const videos = document.querySelectorAll(".video-container");

videos.forEach((video) => {
  const preview = video.querySelector(".preview");
  preview.addEventListener("click", function (event) {
    toggleFullScreen(event, video, preview);
  });
});

function toggleFullScreen(event, video, preview) {
  const info = video.querySelector(".information");
  const videoOpacity = video.querySelector("video");

  if (!video.classList.contains("fullscreen")) {
    // Mettre la vidéo en plein écran
    video.classList.add("fullscreen");
    document.addEventListener("click", exitFullScreen);

    // Désactiver les événements de pointeur pour les autres vidéos
    videos.forEach((otherVideo) => {
      if (otherVideo !== video) {
        otherVideo.style.pointerEvents = "none";
        otherVideo.style.display = "none";
      }
    });

    // Ajouter la classe inactive à .preview
    preview.classList.add("inactive");
    info.classList.add("inactive");
    videoOpacity.style.opacity = "1";

    // Démarrer la vidéo
    video.querySelector("video").play();
  } else {
    // Réduire la vidéo à sa taille normale
    video.classList.remove("fullscreen");

    // Réactiver les événements de pointeur pour toutes les vidéos
    videos.forEach((vid) => {
      vid.style.pointerEvents = "auto";
      vid.style.display = "block";
      vid.querySelector(".preview").classList.remove("inactive");
      vid.querySelector(".information").classList.remove("inactive");
      vid.querySelector("video").style.opacity = "0";
      vid.querySelector("video").pause();
    });
  }

  // Empêcher la propagation de l'événement pour éviter les interférences
  event.stopPropagation();
}

function exitFullScreen() {
  const fullScreenVideo = document.querySelector(".video-container.fullscreen");

  if (fullScreenVideo) {
    // Réduire la vidéo à sa taille normale
    fullScreenVideo.classList.remove("fullscreen");

    // Réactiver les événements de pointeur pour toutes les vidéos
    videos.forEach((vid) => {
      vid.style.pointerEvents = "auto";
      vid.style.display = "block";
      vid.querySelector(".preview").classList.remove("inactive");
      vid.querySelector(".information").classList.remove("inactive");
      vid.querySelector("video").style.opacity = "0";
      vid.querySelector("video").pause();
    });
  }

  // Retirer l'écouteur d'événements pour éviter les conflits
  document.removeEventListener("click", exitFullScreen);
}
